import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CTA from '../components/CTA';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>Not found</h1>
      <p>This page doesn&apos;t exist.</p>

      <CTA to="/">Back Home</CTA>
    </Layout>
  );
}

export default NotFoundPage;
